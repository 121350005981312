import saveAs from 'file-saver';
import { BASE_URL } from '@shared/http/http.service';
import { type EntityType } from '@shared-lib/entity-type/entity-type.enum';
import { type NullableString } from '../documents/store/documents.types';
import { ImageType } from '@shared/image/image.enum';
import type { Image } from '@shared/image/image.types';
import type { GeneralSettingsIconsPath } from '@shared-lib/general-settings/general-settings-icons-path.enum';

type ImageParams = {
    image?: Image | null | undefined;
    type?: ImageType;
};

type GetFileParams = {
    entityType?: EntityType;
    // TODO(xakeppok): make enum with ApiRoutes and use it on front end backend
    apiRoute?: string;
    fileName?: string | null;
    imageParams?: ImageParams;
};

export const getImageURL = (params: ImageParams = {}): string | undefined => {
    const { type = ImageType.FULL, image } = params;

    if (!image) {
        return;
    }

    let imageURL: string | null = image.fileName;

    if (image) {
        switch (type) {
            case ImageType.ICON:
                imageURL = image.iconFileName;
                break;
            case ImageType.THUMBNAIL:
                imageURL = image.thumbnailFileName;
                break;
        }
    }
    return `${BASE_URL}/${image?.controllerName}/images/${imageURL}`;
};

export const getFileLink = ({ fileName, entityType, apiRoute }: GetFileParams): string => {
    return `${BASE_URL}/${entityType ?? apiRoute}/${fileName}`;
};

export const getLogoUrl = (url: GeneralSettingsIconsPath): string =>
    getFileLink({
        apiRoute: 'general-settings/images',
        fileName: url,
    });

export const dummyQuery = (url: string): string => {
    return `${url}?v=${new Date().getTime()}`;
};

type DownloadFileParams = {
    fileName: string;
    displayName: NullableString;
    entityType: EntityType;
    extension: NullableString;
};

// TODO(xakeppok): maybe remove extension, fileName will be enough ?
export const downloadFile = async ({
    entityType,
    fileName,
    displayName,
    extension,
}: DownloadFileParams): Promise<void> => {
    const link = getFileLink({ entityType, fileName });
    if (!link) {
        return;
    }
    if (!displayName || !extension) {
        saveAs(link);
        return;
    }
    const response = await fetch(link);
    const blob = await response.blob();
    saveAs(blob, `${displayName}${extension}`);
};
