export enum EntityType {
    DOCUMENTS = 'documents',
    TIMELOG = 'timelog',
    LINKS = 'links',
    CREDENTIALS = 'credentials',
    ACTIVITY_LOG = 'activity_log',
    EMPLOYEES = 'employees',
    OPEN_POSITIONS = 'open_positions',
    CLIENTS = 'clients',
    PROJECTS = 'projects',
    INVENTORY = 'inventory',
    POSITIONS = 'positions',
    CANDIDATES = 'candidates',
    BACKUPS = 'backups',
    HOLIDAYS = 'holidays',
    STRUCTURE = 'structure',
    SALES = 'sales',
    SETTINGS_GENERAL = 'settings_general',
    SETTINGS_MAIL_BOX = 'settings_mail_box',
    SETTINGS_DATABASE_BACKUP = 'settings_database_backup',
    SETTINGS_HOLIDAYS_SCHEDULE = 'settings_holidays_schedule',
    SETTINGS_WORKING_WEEK = 'settings_working_week',
    SETTINGS_VACATION_POLICY = 'settings_vacation_policies',
    SETTINGS_SICKNESS_POLICY = 'settings_sickness_policies',
    SETTINGS_STORAGE = 'settings_storage',
}
export const attachmentEntityTypes = [EntityType.DOCUMENTS, EntityType.LINKS, EntityType.CREDENTIALS];

export type AttachmentEntityType = (typeof attachmentEntityTypes)[number];

export const companyEntitiesHaveAttachments = [EntityType.POSITIONS, EntityType.INVENTORY];
export const customersEntitiesHaveAttachments = [EntityType.CLIENTS, EntityType.PROJECTS];
export const teamEntitiesHaveAttachments = [EntityType.EMPLOYEES];
